<template>
  <GreenBlock class-name="green-section--big mb-0">
    <v-container>
      <h1 class="page-title max-width mb-9">Vielen Dank für die Bestellung Ihres Strompakets</h1>
      <div class="green-section__content mb-5">
        <div class="green-section--left">
          <div class="page-subtitle mb-12">
            Das neue Strompaket wird Ihrem Kundenkonto in Kürze gutgeschrieben.
            Sie können dieses Fenster nun schließen.
          </div>
        </div>
        <div class="green-section--right">
          <img src="~@/assets/images/eprimo-face-3.svg" alt="eprimo-face">
        </div>
      </div>
    </v-container>
  </GreenBlock>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: "ThankYouPackage",
  components: {
    GreenBlock
  }
}
</script>

<style lang="scss" scoped>

.green-section.green-section--big .green-section--right {
  margin: -30px 50px -70px 0;
}
</style>